#Navigation{
    width: 100%;
    /* position: absolute; */
    /* top: 0; */
    /* z-index: 1; */
    background-color: white;
    border-radius: 0 0 4px 4px;
}
.Navigation-Container{
    max-height: 30px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-auto-rows: 30px;
    align-items: center;
    justify-items: center;
}
.Navigation-Container.Open{
    max-height: 120px;
}
.Nav-Link{
    transition: all .5s;
    color: black;
    text-decoration: none;
    width: 100%;
}
#NavigationToggle{
    background-color: transparent;
    border: 0 solid transparent;
    margin: 0;
    width: 100%;
    height: 30px;
}
#NavigationToggle:focus{
   outline: none;
}
.Hamburger{
    height: 20px;
}
.Nav-Link:hover{
    color: darkgray;
}
@media (max-width: 700px) {
    .Navigation-Container {
      grid-template-columns: 1fr !important
    }
  }