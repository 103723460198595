.App {
  text-align: center;
  max-width: 1280px;
  margin: 0 auto;
}
/* .Elements{
  margin-top: 30px;
} */
section{
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  position: relative;
}

section p{
  padding: 0 10px;
}

h1{
  font-size: 2.5em;
}

@media (max-width: 700px) {
  h1{
    font-size: 1.68em;
  }
}
