#PictureCarousel{
    display: block;
}
.ImageContainer{
    background-color: white;
}
.HeroImage{
    max-height: 560px;
    width: auto;
    object-fit: contain;
}

@media (max-width: 700px) {
    #Hero {
        grid-template-rows: auto;
    }
    .HeroImage {
      max-width: 100vw;
    }
  }