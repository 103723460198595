.PhoneNumber, .Address{
    text-decoration: none;
    color: black;
    font-style: normal;
}
.StoreName{
    font-weight: bolder;
}
.AddressContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
@media (max-width: 700px) {
    .AddressContainer {
      grid-template-columns: 1fr;
    }
    h1{
      font-size: 1.68em;
    }
  }